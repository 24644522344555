import React from "react";
import "../styles/global.scss";
import styled from "styled-components"
import {Link} from "gatsby";

const JobTagContainer = styled.div`
  background-color: #${props => props.color};
  color: ${props => props.color === 'b7b783' ? "black" : "white" };
`

const JobTag = ({children, color}) => {

    return (
        <JobTagContainer color={color} className="jobtag">
            <p className="small jobtagtext">{children}</p>
        </JobTagContainer>
    )
}
export default JobTag
